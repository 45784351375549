<template>
  <section id="feather-icons">
    <div class="d-flex flex-wrap flex-grid">
      <download-item
      fileName="logo-desktop.svg"
      cardTitle="Desktop logo (main)"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="logo-desktop.png"
      />
      <download-item
      fileName="logo-desktop-black.svg"
      cardTitle="Desktop logo (main) black"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="logo-desktop-black.png"
      />
      <download-item
      fileName="logo-desktop-white.svg"
      cardTitle="Desktop logo (main) white"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      class="dark-card"
      :groupButton="true"
      pngFileName="logo-desktop-white.png"
      />
      <download-item
      fileName="IOT_Logo_by_IEG.svg"
      cardTitle="Logo IOT by IEG"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="IOT_Logo_by_IEG.png"
      />
      <download-item
      fileName="logo-mobile.svg"
      cardTitle="Mobile logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      class="max-width"
      :groupButton="true"
      pngFileName="logo-mobile.png"
      />
      <download-item
      fileName="logo-mobile-white.svg"
      cardTitle="Mobile logo white"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      class="dark-card max-width"
      :groupButton="true"
      pngFileName="logo-mobile-white.png"
      />
      <download-item
      fileName="logo-mobile-black.svg"
      cardTitle="Mobile logo black"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      class="max-width"
      :groupButton="true"
      pngFileName="logo-mobile-black.png"
      />
      <download-item
      fileName="app-icon-blue.svg"
      cardTitle="Icon app blue"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="app-icon-white.svg"
      cardTitle="Icon app white"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      class="dark-card"
      />
      <download-item
      fileName="logo-desktop-v2.svg"
      cardTitle="Desktop logo Version 2"
      cardText="This is the 2nd version of the logo for the desktop with 2 lines of names. Please use this logo for the right purpose."
      />
      <download-item
      fileName="logo-desktop-v2-black.svg"
      cardTitle="Desktop logo Version 2 black"
      cardText="This is the 2nd version of the logo for the desktop with 2 lines of names. Please use this logo for the right purpose."
      />
      <download-item
      fileName="Logo IEG - Main.svg"
      cardTitle="IEG Logo (Main)"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="Logo IEG - Main-01.png"
      />
      <download-item
      fileName="Logo IEG - Secondary Logo.svg"
      cardTitle="IEG Logo (Secondary)"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="Logo IEG - Secondary Logo-01.png"
      class="dark-card"
      />
      <download-item
      fileName="Logo IEG - White Logo.svg"
      cardTitle="IEG Logo (White)"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      :groupButton="true"
      pngFileName="Logo IEG - White Logo-01.png"
      class="dark-card"
      />
      <hr class="clearfix w-100 mt-2 mb-3"/>
      <download-item
      fileName="icon-setup-other.svg"
      cardTitle="Icon setup"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-setup-listening.svg"
      cardTitle="Icon setup listening"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-setup-reading.svg"
      cardTitle="Icon setup reading"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-setup-writing.svg"
      cardTitle="Icon setup writing"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-setup-speaking.svg"
      cardTitle="Icon setup speaking"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="acc-setting.svg"
      cardTitle="Account setting"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-no-answer.svg"
      cardTitle="Icon no answer"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="save-draft.svg"
      cardTitle="Icon save draft"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-add-answer.svg"
      cardTitle="Icon add answer"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="graph_403.svg"
      cardTitle="Icon 403"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="graph_404.svg"
      cardTitle="Icon 404"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="submit-essay.svg"
      cardTitle="Icon submit essay"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="speaking-icon.svg"
      cardTitle="Icon speaking"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="speaking-grey-icon.svg"
      cardTitle="Icon speaking grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="reading-icon.svg"
      cardTitle="Icon reading"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="reading-grey-icon.svg"
      cardTitle="Icon reading grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="writing-icon.svg"
      cardTitle="Icon writing"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="writing-grey-icon.svg"
      cardTitle="Icon writing grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="listening-icon.svg"
      cardTitle="Icon listening"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="listening-grey-icon.svg"
      cardTitle="Icon listening grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-briefcase-active.svg"
      cardTitle="Icon briefcase"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-briefcase.svg"
      cardTitle="Icon briefcase grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-parent-active.svg"
      cardTitle="Icon parent"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-parent.svg"
      cardTitle="Icon parent grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-partner-active.svg"
      cardTitle="Icon partner"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-partner.svg"
      cardTitle="Icon partner grey"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-video-writing.svg"
      cardTitle="Icon writing camcorder"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-video-reading.svg"
      cardTitle="Icon reading camcorder"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-video-speaking.svg"
      cardTitle="Icon speaking camcorder"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-video-listening.svg"
      cardTitle="Icon listening camcorder"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-video-other.svg"
      cardTitle="Icon camcorder"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-feedback-listening.svg"
      cardTitle="Icon listening feedback"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-feedback-reading.svg"
      cardTitle="Icon reading feedback"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-feedback-writing.svg"
      cardTitle="Icon writing feedback"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-feedback-speaking.svg"
      cardTitle="Icon speaking feedback"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-feedback-other.svg"
      cardTitle="Icon feedback"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-interview.svg"
      cardTitle="icon interview"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-purchase-package.svg"
      cardTitle="icon purchase package"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="confirmation-email.svg"
      cardTitle="confirmation email"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="card-ae.svg"
      cardTitle="Americal express payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="card-jcb.svg"
      cardTitle="JCB payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="card-master.svg"
      cardTitle="Master card payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="card-paypal.svg"
      cardTitle="Paypal payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-alipay.svg"
      cardTitle="Alipay payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="card-visa.svg"
      cardTitle="Visa payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-ant.svg"
      cardTitle="Ant payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-jd.svg"
      cardTitle="JD payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="icon-union.svg"
      cardTitle="China union payment logo"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
      <download-item
      fileName="bank-transfer.svg"
      cardTitle="Icon bank transfer"
      cardText="Some quick example text to build on the card title and make up the bulk of the card's content."
      />
    </div>
  </section>
</template>

<script>
import {
  BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip, BButton, BImg, BCardTitle
} from 'bootstrap-vue'
import DownloadItem from './DownloadItem.vue'

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BCardText,
    BInputGroupPrepend,
    BButton,
    BImg,
    BCardTitle,
    DownloadItem,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/ui-download.scss';
</style>
