<template>
  <b-card
    tag="article"
    class="card-download text-center"
  >
    <b-img class="mb-2 img-download" :src="require(`@/assets/images/download/${fileName}`)" fluid alt="Responsive image" />
    <b-card-title>{{ cardTitle }}</b-card-title>
    <b-card-text>
      {{cardText}}
    </b-card-text>
    <div class="card-download__btn-wrap" v-if="groupButton">
      <b-dropdown right text="Download">
        <b-dropdown-item :href="require(`@/assets/images/download/${fileName}`)" download>Download SVG</b-dropdown-item>
        <b-dropdown-item :href="require(`@/assets/images/download/${pngFileName}`)" download>Download PNG</b-dropdown-item>
      </b-dropdown>
    </div>
    <div  class="card-download__btn-wrap" v-else>
      <b-button :href="require(`@/assets/images/download/${fileName}`)" variant="primary" download>Download</b-button>
    </div>    
  </b-card>
</template>

<script>  
  import {
    BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BCard, BCardText, VBTooltip, BButton, BImg, BCardTitle, BDropdown, BDropdownItem
  } from 'bootstrap-vue'
  export default {
    props: {
      fileName: {
        type: String,
        default: '',
      },
      cardTitle: {
        type: String,
        default: '',
      },
      cardText: {
        type: String,
        default: '',
      },
      zipFile: {
        type: String,
        default: '',
      },
      groupButton: {
        type: Boolean,
        default: false
      },
      pngFileName: {
        type: String,
        default: ''
      }
    },
    components: {
      BCard,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BCardText,
      BInputGroupPrepend,
      BButton,
      BImg,
      BCardTitle,
      BDropdown,
      BDropdownItem
    }
  }
</script>

<style lang="scss" scoped>

</style>